.root {
  width: 100%;
  min-width: 100px;
  height: 100%;

  position: relative;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  padding: 9px;
}

.coins {
  font-weight: 800;
  font-size: 14px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  z-index: -1;

  fill: rgba(38, 38, 58, 0.75);
}