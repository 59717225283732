.root {
  display: flex;
  gap: 6px;

  font-size: 14px;
  font-weight: 600;
}

.twitter {
  color: #379fff;
}
.facebook {
  color: #1877f2;
}
.tiktok {
  color: #ff1f64;
}
.discord {
  color: #5865f2;
}
.instagram {
  color: #cb38a0;
}
