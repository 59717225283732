.big {
  font-weight: 800;
  font-size: 66px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.sectionDescription {
  background: linear-gradient(90deg, #18A9FF 0%, #9229FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  font-weight: 700;
  font-size: 16px;
  text-align: center;

  margin-bottom: 5px;

  width: fit-content;
}
