.root {
  background-color: #11111f;

  width: 100%;
  max-width: 1256px;
  
  margin: 50px auto;
  padding: 24px 0;

  border-radius: 10px;

  display: flex;
  flex-direction: column;

  z-index: 1;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand {
  display: flex;
  gap: 16px;
  align-items: center;

  p {
    // Copyright.

    color: #525174;
    font-size: 14px;

    margin-right: 1em;
  }
}

.socialButtons {
  display: flex;
  gap: 5px;
}

.secondRow {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  font-weight: bold;
  color: #9391c9;
  font-size: 14px;

  a {
    color: #9391c9;
    text-decoration: none;
  }
  a:hover {
    opacity: 0.85;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #03030B;

  margin: 24px 0;
}

.bottom {
  display: flex;
  align-items: center;
  gap: 22px;

  padding: 0 24px;
}

.disclaimer {
  color: #525174;
  line-height: 19px;
  font-size: 14px;
  font-weight: 500;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 28px;

  padding: 0 24px;
}

@media screen and (max-width: 1256px) {
  .root {
    border-radius: 0;
    margin-bottom: 0;
  }  
}
@media screen and (max-width: 635px) {
  .firstRow {
    justify-content: center;

    flex-wrap: wrap;
    row-gap: 1em;
  }
  .socialButtons {
    flex-wrap: wrap;
    justify-content: center;
  }
  .secondRow {
    justify-content: center;
    text-align: center;
  }
  .bottom {
    justify-content: center;
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 400px) {
  .brand {
    flex-direction: column;
  }
}

