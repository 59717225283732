.root {
  color: #989eae;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  font-weight: 600;

  padding: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active {
  border-radius: 7px;
  background: #26263A;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px rgba(255, 255, 255, 0.03) inset;
}

.root:hover:not(.active) {
  opacity: 0.85;
}