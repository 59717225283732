.root {
  position: relative;
}

.display {
  color: #546BFF;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  svg {
    fill: #546BFF;
  }
}
