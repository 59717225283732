.root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 110px;
  height: 30px;

  background-color: #ffffff;
  border-radius: 5px;

  color: #000000;
  font-weight: 800;
  font-size: 12px;

  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  margin: auto;

  z-index: 1;

  svg {
    fill: #000000;
  }
}
