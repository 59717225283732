.root {
  width: 48px;
  height: 34px;

  background: #26263A;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05), inset 0px 1px 0px rgba(255, 255, 255, 0.03);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;

  transition: opacity 0.25s ease-in-out;
  
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}