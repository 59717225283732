.root {
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 4px;

  flex-grow: 1;

  height: 100%;

  padding: 0 12px;

  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  z-index: -1;

  fill: #03030a;
  opacity: 0.5;
}

.copy:hover {
  opacity: 0.85;
}

.code {
  font-weight: 700;
  font-size: 14px;

  flex-grow: 1;
}

.copy {
  cursor: pointer;

  border-radius: 50%;
  background: rgba(152, 158, 174, 0.33);

  width: 18px;
  height: 18px;

  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: auto;
  }
}
.copied {
  width: 8px;
  height: 8px;
  padding: 4px;

  border: 1px solid #3fb950;
}