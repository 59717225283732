.root {
  width: 170px;
  height: 222px;

  background: radial-gradient(100% 100% at 50% 0%, rgba(36, 36, 58, 0.15) 0%, rgba(36, 36, 58, 0) 100%), rgba(22, 22, 37, 0.8);
  backdrop-filter: blur(45px);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 16px;
}

.top {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.image {
  border-radius: 50%;
  border: 4px solid rgba(8, 8, 17, 0.5);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}

.name {
  font-weight: 700;
  font-size: 16px;
}

.bottom {
  width: 100%;

  div {
    width: 100%;
  }
}
