@use 'header' as header;

.root {
  max-width: 250px;
  width: 100%;

  background: radial-gradient(94.58% 94.58% at 50% 5.42%, rgba(29, 29, 47, 0.2) 0%, rgba(0, 0, 0, 0) 100%, rgba(22, 22, 37, 0) 100%), rgba(22, 22, 37, 0.8);
  backdrop-filter: blur(45px);
  border-radius: 8px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.12);
  
  position: absolute;
  right: 16px;
  top: 16px;

  z-index: 2;

  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.link {
  padding: 0.5em 1em;
}
.link:first-child {
  padding-top: 1em;
}
.link:last-child {
  padding-bottom: 1em;
}

.group {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 1em;
}
.profileGroup {
  @extend .group;

  justify-content: space-between;
}
.buttonGroup {
  @extend .group;

  justify-content: center;
}

@media screen and (min-width: header.$breakpoint) {
  .root {
    display: none;
  }
}