.root {
  width: 100%;
  min-height: 375px;

  background: linear-gradient(270deg, rgba(22, 22, 37, 0.8) 0%, rgba(22, 22, 37, 0) 100%);
  backdrop-filter: blur(3px);
  border-radius: 12px;

  display: flex;
  flex-direction: row;
  gap: 35px;

  padding: 35px 45px;
}

// TODO: Move this to a common file.
.paragraph {
  font-size: 14px;
  color: #9391C9;
}

.rafflesLeft {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
}

.rafflesRight {
  flex: 1.25;
  
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  min-height: calc(min(100vw, 620px) * 0.55);
}

.raffleTitle {
  font-weight: 700;
  font-size: 32px;
}

.image {
  object-fit: cover;
  
  border-radius: 8px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: -1;

  position: absolute;

  max-width: 535px;
  max-height: 300px;

  width: 100%;
  height: auto;
}


@media screen and (max-width: 950px) {
  .root {
    flex-direction: column;

    text-align: center;

    padding: 1.5em 1em;
  }

  .rafflesLeft {
    justify-content: center;
    align-items: center;
  }
}
